<template>
	<svg
		width="48"
		height="48"
		viewBox="0 0 48 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g filter="url(#filter0_ddd_28_966)">
			<rect x="4" y="3" width="40" height="40" rx="10" fill="#590EFA" />
		</g>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M25.415 13.5C27.411 13.5 29.2176 14.3109 30.5234 15.6212C29.7284 15.7907 28.9722 16.0399 28.2642 16.4062C26.1108 17.5202 24.5787 19.6053 23.494 23.173C23.44 23.3504 23.3876 23.5261 23.3365 23.7L12.0706 23.7C12.6494 20.3849 15.4531 17.8345 18.8863 17.6441C20.039 15.1952 22.529 13.5 25.415 13.5ZM12.0005 25.7375C12.3752 29.3476 15.3672 32.1803 19.046 32.3051V32.3104H19.0698L19.0998 32.2983C19.9271 31.9628 20.5108 31.43 20.9884 30.6753C21.4839 29.8923 21.8606 28.8779 22.2438 27.5888C22.4117 27.024 22.5758 26.4246 22.752 25.7811L22.7521 25.7808L22.7521 25.7807L22.764 25.7375L12.0005 25.7375ZM29.2004 18.2158C29.9705 17.8175 30.8419 17.5899 31.8545 17.4615C32.2821 18.3068 32.548 19.2477 32.6118 20.2437C34.6288 21.3236 36.0007 23.451 36.0007 25.8988C36.0007 29.383 33.2211 32.218 29.7583 32.3071V32.3104H22.329C22.4637 32.1358 22.5905 31.9538 22.7101 31.7648C23.3634 30.7323 23.8038 29.4915 24.1968 28.1692C24.3715 27.5817 24.5419 26.9594 24.7172 26.3189L24.7173 26.3187L24.7173 26.3186L24.7877 26.0615C24.9895 25.3258 25.2014 24.5613 25.4433 23.7656C26.4384 20.4927 27.7142 18.9847 29.2004 18.2158Z"
			fill="white"
		/>
		<defs>
			<filter
				id="filter0_ddd_28_966"
				x="0.233733"
				y="0.04079"
				width="47.5325"
				height="47.5325"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feMorphology
					radius="0.269019"
					operator="dilate"
					in="SourceAlpha"
					result="effect1_dropShadow_28_966"
				/>
				<feOffset dy="0.807057" />
				<feGaussianBlur stdDeviation="1.74862" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_28_966"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.13451" />
				<feGaussianBlur stdDeviation="0.13451" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
				/>
				<feBlend
					mode="normal"
					in2="effect1_dropShadow_28_966"
					result="effect2_dropShadow_28_966"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation="0.0672548" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.19 0"
				/>
				<feBlend
					mode="normal"
					in2="effect2_dropShadow_28_966"
					result="effect3_dropShadow_28_966"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect3_dropShadow_28_966"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
</template>
